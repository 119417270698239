<template>
  <div>
    <div class="welcome-message">
      <img :src="logoURL" alt="logo de la empresa" class="logo">

      <div class="content" :class="{ 'two-column': welcomeMessagePictureURL }">
        <div>
          <section v-html="welcomeMessage"></section>
          <div>
            <custom-button variant="primary" @click="$router.push('/')">
              Iniciar Encuesta
              <font-awesome-icon icon="arrow-right" slot="append-icon" />
            </custom-button>
          </div>
        </div>

        <figure>
          <img :src="welcomeMessagePictureURL" alt="">
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CustomButton from '../components/CustomButton.vue';

export default {
  name: 'WelcomeMessage',

  components: { CustomButton },

  computed: {
    ...mapState({
      logoURL: (state) => state.company.logoURL,
      welcomeMessage: (state) => state.survey.welcomeMessage,
      welcomeMessagePictureURL: (state) => state.survey.welcomeMessagePictureURL,
      finishedSurvey: (state) => state.userProfile.finishedSurvey,
      activeSurveyId: (state) => state.company.activeSurveyId,
      endDate: (state) => state.survey.endDate,
    }),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.activeSurveyId) return next('/no-survey');

      const endDate = new Date(`${vm.endDate}T00:00:00`.replace(/-/g, '/').replace(/T.+/, '')).toISOString().slice(0, 10);

      const today = new Date().toISOString().slice(0, 10);

      if (endDate < today) return next('/no-survey');

      if (vm.finishedSurvey) return next('/thank-you');

      return next();
    });
  },
};
</script>

<style lang="scss" scoped>
.view {
  padding: var(--lengthLg2) 0;
  background-color: var(--grayColor1);
}

.welcome-message {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-items: flex-start;
  gap: var(--lengthMd3);
  margin: 0 auto;
  padding: var(--lengthMd1) var(--lengthMd1) var(--lengthLg1);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 10px 2px var(--grayColor2);
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--lengthMd3);
  height: fit-content;
  overflow: auto;
  & > div:first-child {
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
button {
  width: fit-content;
}

.logo {
  max-width: 120px;
}

img {
  max-height: 100%;
}

.btn {
  padding: var(--lengthMd1);
  font-size: var(--lengthMd2);
}

figure, section {
  height: fit-content;
}

@media only screen and (min-width: 768px) {
  .welcome-message {
    padding: var(--lengthLg1);
  }
}

@media only screen and (min-width: 980px) {
  .view {
    max-height: 100vh;
    overflow: hidden;
  }

  .welcome-message {
    height: 100%;
    padding: var(--lengthLg2) var(--lengthLg3);
  }

  .content.two-column {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
